'use strict';

// Sticky global navigation
{
  const slideDownNav = {
    el: {
      $header: $('.js_header'),
      headerClass: '.js_header',
      activateSectionClass: '.js_header-activate'
    },

    config: {
      active: false
    },

    init() {
      this.toggleNav($(window).scrollTop());
      this.scrollHandler();
    },

    scrollHandler() {
      $(window).scroll(() => {
        this.toggleNav($(window).scrollTop());
      });
    },

    toggleNav(scrollPos) {
      const activatePos =
        $(this.el.activateSectionClass).offset().top -
        $(this.el.headerClass).outerHeight();

      if (scrollPos >= activatePos && this.config.active !== true) {
        if (this.config.active !== true) {
          this.slideDown();

          this.config.active = true;
        } else {
          this.slideDown();
        }

        this.config.active = true;
      }

      if (scrollPos < activatePos && this.config.active !== false) {
        this.slideUp();

        this.config.active = false;
      }
    },

    slideDown() {
      this.el.$header.addClass('active');
    },

    slideUp() {
      this.el.$header.removeClass('active');
    }
  };

  // Only enable the sticky dropdown navigation on the top page
  if ($('.js_header').length) {
    slideDownNav.init();
  }
}

// Hamburger menu (SP)
{
  const spMenu = {
    el: {
      $headerLogo: $('.nav-sp__logo'),
      $menu: $('.js_nav-sp'),
      $openBtn: $('.js_nav-sp-open'),
      $closeBtn: $('.js_nav-sp-close'),
      $navLink: $('.js_nav-sp .js_link')
    },

    init() {
      this.openHandler();
      this.closeHandler();
      this.linkHandler();
    },

    openHandler() {
      const _this = this;

      this.el.$openBtn.on('click', function (e) {
        e.preventDefault();
        _this.openMenu();
        return 0;
      });
    },

    closeHandler() {
      const _this = this;

      this.el.$closeBtn.on('click', function (e) {
        e.preventDefault();
        _this.closeMenu();
        return 0;
      });
    },

    openMenu() {
      this.el.$menu.addClass('active');
      this.el.$headerLogo.addClass('inactive');
    },

    closeMenu() {
      this.el.$menu.removeClass('active');
      this.el.$headerLogo.removeClass('inactive');
    },

    linkHandler() {
      this.el.$navLink.on('click', () => {
        this.closeMenu();
      });
    }
  };

  if ($('.js_nav-sp').length) {
    spMenu.init();
  }
}

// Hero
{
  const hero = {
    el: {
      $title: $('.js_hero-title')
    },

    init() {
      this.fadeInTitle();
    },

    fadeInTitle() {
      this.el.$title.addClass('active');
    }
  };

  if ($('.js_hero-title').length) {
    hero.init();
  }
}

// Section wipes
{
  const sectionWipes = {
    el: {
      $section: $('.js_section-wipe')
    },

    init() {
      this.scrollHandler();
    },

    scrollHandler() {
      const _this = this;

      $(window).on('scroll', function () {
        //_this.setHeights();
        _this.setSticky();
        _this.changeHeight();
      });
    },

    setSticky() {
      const _this = this;

      const $section = _this.el.$section;
      const $wipesInner = $section.find('.section__wipe__inner');

      let currentPos = $(window).scrollTop();
      let elPos = $section.offset().top;

      if (currentPos >= elPos) {
        $wipesInner.addClass('fixed');
      } else {
        $wipesInner.removeClass('fixed');
      }
    },

    setHeights() {
      const $section = _this.el.$section;

      const currentPos = $(window).scrollTop();
      const $wipes = $section.find('.section__wipe');
    },

    changeHeight() {
      const _this = this;

      const $section = _this.el.$section;

      const currentPos = $(window).scrollTop();
      const $wipes = $section.find('.section__wipe');

      $wipes.each(function () {
        const $wipe = $(this);

        const wipeSectionHeight = $wipe.height();
        const wipePosBottom = $wipe.offset().top + wipeSectionHeight;
        const wipeHeight = wipePosBottom - currentPos;

        if (wipeHeight < wipeSectionHeight + 100) {
          $wipe.find('.section__wipe__inner').height(wipeHeight);
        }
      });

      // console.log('Current pos: ' + currentPos);
      // console.log('Section pos: ' + elPos);
      // console.log('Wipe bottom pos: ' + wipePosBottom);
      // console.log('Wipe height: ' + wipeHeight);
    }
  };

  if ($('.js_section-wipe').length) {
    sectionWipes.init();
  }
}

// Instagram
{
  const instagram = {
    init() {
      this.initializeInstagram();
    },

    initializeInstagram() {
      const feed = new Instafeed({
        accessToken:
          'IGQVJXWU9WWUh3ZAzMwajctSUNWNkh3OXpCdWUtcGtGVjBUcVZAYeHVNdjgyb3NteTNOczZAJaDRUTHlxOXRTdmpxQkthbWp5Sm5yNldjQUQ1d2U3NFk1NjdDREJveWNFQ2pZAMXFOd3AxZAzBHZAFpwZAkRBQQZDZD',
        limit: 15,
        template:
          '<a href="{{link}}" target="_blank"><img title="{{caption}}" src="{{image}}" loading="lazy" width="500" height="500" /></a>'
      });
      feed.run();
    }
  };

  if ($('#instafeed').length) {
    instagram.init();
  }
}

// Accordion
{
  const accordion = {
    el: {
      $acr: $('.js_acr'),
      toggleClass: '.js_acr_toggle',
      bodyClass: '.js_acr_body'
    },

    init() {
      this.clickHandler();
    },

    clickHandler() {
      const _this = this;

      this.el.$acr.on('click', function () {
        _this.toggleAcr($(this));
      });
    },

    toggleAcr($acr) {
      $acr.find(this.el.bodyClass).slideToggle(300);
      $acr.find(this.el.toggleClass).toggleClass('active');
    }
  };

  if ($('.js_acr').length) {
    accordion.init();
  }
}

// Slider
{
  const slider = {
    el: {
      $slider: $('.js_slider')
    },

    init() {
      this.initiateSlick();
    },

    initiateSlick() {
      const _this = this;

      _this.el.$slider.slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true
      });
    }
  };

  if ($('.js_slider').length) {
    slider.init();
  }
}

// Slide to section links
{
  $('.js_link').on('click', function (e) {
    e.preventDefault();

    const target = $(this).attr('href');
    //$(target).addClass('unset');
    $('.section--sticky').addClass('unset');
    const offset = $(target).offset().top;
    //$(target).removeClass('unset');
    $('.section--sticky').removeClass('unset');

    $('html,body').animate({scrollTop: offset}, 700);

    return 0;
  });
}
